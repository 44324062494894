import { LitElement, html, css } from 'lit-element';

class HrCustom extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        width: var(--custom-hr-width, 100%);
      }

      .gradient {
        height: 1px;
        background: linear-gradient(to right, var(--nsys-blue3), fuchsia, yellow);
        width: 100%;
      }

      .flat {
        height: 1px;
        background: var(--sl-color-neutral-400);
      }

      @media print {         
        .gradient {
          background: initial;
          border-bottom:1px solid var(--nsys-green3);
        }
      }
    `;
  }
  
  static get properties() {
    return {
      width: { type: String },
      variant: { type: String }
    };
  }

  constructor() {
    super();
    this.width = '100%';
    this.variant = 'flat';
  }

  updated(changedProperties) {
    if (changedProperties.has('width')) {
      this.style.setProperty('--custom-hr-width', this.width);
    }
  }

  render() {
    return html`<div class="${this.variant}"></div>`;
  }
}

customElements.define('hr-custom', HrCustom);
