import { BaseElement, html, css, formCss } from 'Elements';
import Cropper from 'cropperjs';
import { Sleep } from 'Utils';
import Fetcher from 'Utils/Fetcher';

class customTree extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`

        sl-tree {
          --indent-guide-width: 1px;
          --indent-guide-color: var(--sl-color-neutral-300);
        }
        
        sl-tree-item::part(label) {
          font-size:0.8rem;
        }

        sl-tree-item::part(expand-button) {
          /* use that to handle item height */
          padding-top:0.2rem;
          padding-bottom:0.2rem;
          padding-right:0.2rem;
          rotate: none;
        }

        sl-icon {
          zoom:0.1;
        }

        sl-tree-item::part(indentation) {
          /* left padding */
          /* width:0.5em; */
        }

        scrollable-component {
          height:100%;
          overflow-y:auto;
        }
      `
    ]
  }

  static get properties() {
    return {
      apiEndpoint: { type: String, attribute: 'api-endpoint' },
    }
  }

  constructor() {
    super();
    this.apiEndpoint = '';
  }

  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('apiEndpoint')) {
      this.getData();
    }
  }

  async getData() {
    const response = await Fetcher.get(this.apiEndpoint);
    if (!response) return;
    console.log('data', response.data);
  }

  render() {
    return html `
      <scrollable-component>
        <sl-tree selection="leaf">
          <m-icon name="folder" slot="expand-icon"></m-icon>
          <m-icon name="folder_open" slot="collapse-icon"></m-icon>

          <sl-tree-item>Logo</sl-tree-item>
          <sl-tree-item expanded>
            Newsletter
            <sl-tree-item>Menace</sl-tree-item>
            <sl-tree-item>
              Autre
              <sl-tree-item>Menace</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
              <sl-tree-item>Autre</sl-tree-item>
            </sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
            <sl-tree-item>Autre</sl-tree-item>
          </sl-tree-item>
          <sl-tree-item>Visuels</sl-tree-item>
        </sl-tree>
      </scrollable-component>
    `
  }
}

customElements.define('custom-tree', customTree);

class ImageBank extends BaseElement {

  static get styles() {
    return [
      formCss,
      css`
        :host {
          display:flex;
          flex:1;
          flex-direction:column;
          overflow:hidden;
          border:1px solid var(--sl-color-neutral-300);
        }

        .fields {
          display:flex;
          padding:5px;
        }

        sl-split-panel {
          --divider-width: 6px;
          height:100%;
          flex:1;
          min-height:0;
        }

        sl-split-panel > div {
          outline:1px solid var(--sl-color-neutral-300);
          display:flex;
          overflow: hidden; 
        }

        cropper-canvas {
          flex:1;
        }

        .image_form_cropper_container {
          display:flex;
          width:100%;
          height:100%;
        }

        custom-tree {
          width:100%;
          flex:1;
          overflow-y: auto;
        }
        
      `
    ]
  }

  static get properties() {
    return {
      src: { type: String },
      height: { type: String },
      width: { type: String },
    }
  }

  constructor() {
    super();
    this.src = '';
    this.onResize = this.onResize.bind(this);
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('height')) {
      this.style.height = this.height;
    }

    if (changedProperties.has('width')) {
      this.style.width = this.width;
    }

    if (changedProperties.has('src')) {
      if (this.src) {
        setTimeout(() => {
          this.initCropper();
        }, 300);
      }
    }
  }

  visibleCallback() {
    super.visibleCallback();
    console.log('visibleCallback');
    window.addEventListener('resize', this.onResize);
  }

  hiddenCallback() {
    super.hiddenCallback();
    console.log('hiddenCallback');
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    clearTimeout(this.timerResize);
    this.timerResize = setTimeout(() => {
      // @FIXME: try to resize/update cropper
    }, 100);
  }

  async initCropper() {
    const img = this.qs('img');
    img.src = this.src;

    img.onerror = (e) => {
      console.error('Load image failed', img.src);
    }

    img.onload = async () => {

      const width = img.naturalWidth;
      const height = img.naturalHeight;   
      const cropper = new Cropper(img, {
        initialCoverage: 1,
        template:`
          <cropper-canvas background scale-step="0.1">
            <cropper-image rotatable scalable translatable></cropper-image>
            <cropper-shade></cropper-shade>
            <cropper-handle action="select" plain></cropper-handle>
            <cropper-selection id="cropperSelection" initial-coverage="0.5" movable resizable>
              <cropper-grid role="grid" bordered covered></cropper-grid>
              <cropper-crosshair centered></cropper-crosshair>
              <cropper-handle action="move" theme-color="rgba(255, 255, 255, 0.35)"></cropper-handle>
              <cropper-handle action="n-resize"></cropper-handle>
              <cropper-handle action="e-resize"></cropper-handle>
              <cropper-handle action="s-resize"></cropper-handle>
              <cropper-handle action="w-resize"></cropper-handle>
              <cropper-handle action="ne-resize"></cropper-handle>
              <cropper-handle action="nw-resize"></cropper-handle>
              <cropper-handle action="se-resize"></cropper-handle>
              <cropper-handle action="sw-resize"></cropper-handle>
            </cropper-selection>
          </cropper-canvas>
        `
      });

      const container = this.qs('.image_form_cropper_container');
      const cropperImage = cropper.getCropperImage();
      const cropperSelection = cropper.getCropperSelection();
      cropperSelection.id = 'cropperSelection';

      cropperImage.$ready(() => {
        if (width < container.offsetWidth || height < container.offsetHeight) {
          cropperImage.$resetTransform();
          cropperImage.$center();
          cropperImage.scalable = false;

          cropperSelection.width = width;
          cropperSelection.height = height;
        }
      
        cropperSelection.$center();
      })
    };
  }

  render() {
    return html`
     
      <div class="fields">
        <sl-input name="title" size="small" placeholder="Nommer cette image"></sl-input>
      </div>

      <sl-split-panel position-in-pixels="201">
        <m-icon slot="divider" name="more_vert"></m-icon>
        <div slot="start">
          <custom-tree
            api-endpoint="public/ticket/classifications"
          ></custom-tree>
        </div>
        <div slot="end">
          <div class="image_form_cropper_container">
            <img/>
          </div>
        </div>
      </sl-split-panel>
    `
  }
}

customElements.define('image-bank', ImageBank);
